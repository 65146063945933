var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isActive,
          expression: "isActive"
        }
      ],
      class: { "is-active": _vm.isActive },
      attrs: {
        "aria-labelledby": _vm.$options.filters.slugify(_vm.name),
        role: "tabpanel",
        tabindex: "0",
        id: _vm.$options.filters.slugify(_vm.name) + "-tab"
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }