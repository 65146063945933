var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { class: _vm.navClass }, [
        _c(
          "ul",
          {
            class: _vm.listClass,
            attrs: { role: "tablist", "aria-label": _vm.ariaLabel }
          },
          _vm._l(_vm.tabs, function(tab, index) {
            return _c(
              "li",
              {
                key: index,
                class: [
                  _vm.listClass + "-item",
                  { "is-active": _vm.isActiveClass(tab.name) }
                ]
              },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "#" + _vm.$options.filters.slugify(tab.name) + "-tab",
                      id: _vm.$options.filters.slugify(tab.name),
                      role: "tab",
                      tabindex: tab.isActive ? "" : "-1",
                      "aria-selected": tab.isActive,
                      "aria-controls":
                        _vm.$options.filters.slugify(tab.name) + "-tab"
                    },
                    on: {
                      click: function($event) {
                        return _vm.selectTab($event, tab)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(tab.name) + " ")]
                )
              ]
            )
          }),
          0
        )
      ]),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }