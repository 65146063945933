var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "tab-components" },
    [
      _c("h1", { staticClass: "heading" }, [
        _vm._v("Using Vue dynamic components as tabs")
      ]),
      _vm._m(0),
      _c(
        "TabList",
        {
          staticClass: "tabs",
          attrs: {
            navClass: "tabs__nav",
            ariaLabel: "Business value",
            listClass: "tabs__list"
          }
        },
        [
          _c(
            "TabItem",
            { staticClass: "tabs__panel", attrs: { name: "Catalog" } },
            [
              _c("h2", { staticClass: "heading" }, [_vm._v("Catalog")]),
              _c("p", { staticClass: "paragraph" }, [
                _vm._v(
                  " Distinctio quae nobis molestiae, ullam animi magni ratione voluptatem harum laborum. Dolore. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio quae nobis molestiae, ullam animi magni ratione voluptatem harum laborum. Dolore. "
                )
              ]),
              _c("p", { staticClass: "paragraph" }, [
                _vm._v(
                  " Adipisicing elit. Distinctio quae nobis molestiae, ullam animi magni ratione voluptatem harum laborum. Dolore. "
                )
              ])
            ]
          ),
          _c(
            "TabItem",
            { staticClass: "tabs__panel", attrs: { name: "Products" } },
            [
              _c("h2", { staticClass: "heading" }, [
                _vm._v("These are our products")
              ]),
              _c("p", { staticClass: "paragraph" }, [
                _vm._v(
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut sunt doloremque, incidunt adipisci maiores, minus facere modi vel similique ad!"
                )
              ]),
              _c("p", { staticClass: "paragraph" }, [
                _vm._v(
                  "Amet eum provident rerum obcaecati dolorum distinctio animi nisi, dolorem in quidem inventore maiores, possimus tenetur atque. Soluta, alias, vel."
                )
              ]),
              _c("p", { staticClass: "paragraph" }, [
                _vm._v(
                  "Veritatis iure blanditiis odio magnam nesciunt culpa sapiente consectetur molestiae, minus itaque quaerat amet cum qui officia laborum rerum. Non."
                )
              ])
            ]
          ),
          _c(
            "TabItem",
            { staticClass: "tabs__panel", attrs: { name: "About" } },
            [
              _c("h2", { staticClass: "heading" }, [
                _vm._v("This is our story")
              ]),
              _c("p", { staticClass: "paragraph" }, [
                _vm._v(
                  "Distinctio quae nobis molestiae, ullam animi magni ratione voluptatem harum laborum. Dolore."
                )
              ])
            ]
          ),
          _c(
            "TabItem",
            { staticClass: "tabs__panel", attrs: { name: "Contact" } },
            [
              _c("h2", { staticClass: "heading" }, [_vm._v("Contact")]),
              _c("p", { staticClass: "paragraph" }, [
                _vm._v(
                  " Consectetur adipisicing elit. Distinctio quae nobis molestiae, ullam animi magni ratione voluptatem harum laborum. Dolore. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio quae nobis molestiae, ullam animi magni ratione voluptatem harum laborum. Dolore. "
                )
              ]),
              _c("p", { staticClass: "paragraph" }, [
                _vm._v(
                  " Fistinctio quae nobis molestiae, ullam animi magni ratione voluptatem harum laborum. Dolore. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio quae nobis molestiae, ullam animi magni ratione voluptatem harum laborum. Dolore. "
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "paragraph" }, [
      _vm._v(
        " Easily switch (aria enabled) tabs by changing a Vue data property. The selected tab is saved in "
      ),
      _c("strong", [_vm._v("Vuex")]),
      _vm._v(". ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }